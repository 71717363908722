

'use strict';
{
  const globals = global;
  const django = globals.django || (globals.django = {});

  
  django.pluralidx = function(n) {
    const v = (n==1 ? 0 : (n%10>=2 && n%10<=4) && (n%100<12 || n%100>=14) ? 1 : n!=1 && (n%10>=0 && n%10<=1) || (n%10>=5 && n%10<=9) || (n%100>=12 && n%100<=14) ? 2 : 3);
    if (typeof v === 'boolean') {
      return v ? 1 : 0;
    } else {
      return v;
    }
  };
  

  /* gettext library */

  django.catalog = django.catalog || {};
  
  const newcatalog = {
    "%(sel)s of %(cnt)s selected": [
      "Wybrano %(sel)s z %(cnt)s",
      "Wybrano %(sel)s z %(cnt)s",
      "Wybrano %(sel)s z %(cnt)s",
      "Wybrano %(sel)s z %(cnt)s"
    ],
    "%s ctg-search-cnt-label": [
      "%s wynik wyszukiwania",
      "%s wyniki wyszukiwania",
      "%s wynik\u00f3w wyszukiwania",
      "%s wynik\u00f3w wyszukiwania"
    ],
    "6 a.m.": "6 rano",
    "6 p.m.": "6 po po\u0142udniu",
    "April": "Kwiecie\u0144",
    "August": "Sierpie\u0144",
    "Available %s": "Dost\u0119pne %s",
    "Cancel": "Anuluj",
    "Choose": "Wybierz",
    "Choose a Date": "Wybierz Dat\u0119",
    "Choose a Time": "Wybierz Czas",
    "Choose a time": "Wybierz czas",
    "Choose all": "Wybierz wszystkie",
    "Chosen %s": "Wybrane %s",
    "Click to choose all %s at once.": "Kliknij, aby wybra\u0107 jednocze\u015bnie wszystkie %s.",
    "Click to remove all chosen %s at once.": "Kliknij, aby usun\u0105\u0107 jednocze\u015bnie wszystkie wybrane %s.",
    "December": "Grudzie\u0144",
    "February": "Luty",
    "Filter": "Filtr",
    "Hide": "Ukryj",
    "January": "Stycze\u0144",
    "July": "Lipiec",
    "June": "Czerwiec",
    "March": "Marzec",
    "May": "Maj",
    "Midnight": "P\u00f3\u0142noc",
    "Noon": "Po\u0142udnie",
    "Note: You are %s hour ahead of server time.": [
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godzin\u0119 do przodu w stosunku do czasu serwera.",
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godziny do przodu w stosunku do czasu serwera.",
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godzin do przodu w stosunku do czasu serwera.",
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godzin do przodu w stosunku do czasu serwera."
    ],
    "Note: You are %s hour behind server time.": [
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godzin\u0119 do ty\u0142u w stosunku do czasu serwera.",
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godziny do ty\u0142u w stosunku do czasu serwera.",
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godzin do ty\u0142u w stosunku do czasu serwera.",
      "Uwaga: Czas lokalny jest przesuni\u0119ty o %s godzin do ty\u0142u w stosunku do czasu serwera."
    ],
    "November": "Listopad",
    "Now": "Teraz",
    "October": "Pa\u017adziernik",
    "Remove": "Usu\u0144",
    "Remove all": "Usu\u0144 wszystkie",
    "September": "Wrzesie\u0144",
    "Show": "Poka\u017c",
    "This is the list of available %s. You may choose some by selecting them in the box below and then clicking the \"Choose\" arrow between the two boxes.": "To lista dost\u0119pnych %s. Aby wybra\u0107 pozycje, zaznacz je i kliknij strza\u0142k\u0119 \u201eWybierz\u201d pomi\u0119dzy listami.",
    "This is the list of chosen %s. You may remove some by selecting them in the box below and then clicking the \"Remove\" arrow between the two boxes.": "To lista wybranych %s. Aby usun\u0105\u0107, zaznacz pozycje wybrane do usuni\u0119cia i kliknij strza\u0142k\u0119 \u201eUsu\u0144\u201d pomi\u0119dzy listami.",
    "Today": "Dzisiaj",
    "Tomorrow": "Jutro",
    "Type into this box to filter down the list of available %s.": "Wpisz co\u015b tutaj, aby wyfiltrowa\u0107 list\u0119 dost\u0119pnych %s.",
    "Yesterday": "Wczoraj",
    "You have selected an action, and you haven\u2019t made any changes on individual fields. You\u2019re probably looking for the Go button rather than the Save button.": "Wybrano akcj\u0119, lecz nie dokonano \u017cadnych zmian w polach. Prawdopodobnie szukasz przycisku \u201eWykonaj\u201d, a nie \u201eZapisz\u201d.",
    "You have selected an action, but you haven\u2019t saved your changes to individual fields yet. Please click OK to save. You\u2019ll need to re-run the action.": "Wybrano akcj\u0119, lecz cz\u0119\u015b\u0107 zmian w polach nie zosta\u0142a zachowana. Kliknij OK, aby zapisa\u0107. Aby wykona\u0107 akcj\u0119, nale\u017cy j\u0105 ponownie uruchomi\u0107.",
    "You have unsaved changes on individual editable fields. If you run an action, your unsaved changes will be lost.": "Zmiany w niekt\u00f3rych polach nie zosta\u0142y zachowane. Po wykonaniu akcji, zmiany te zostan\u0105 utracone.",
    "abbrev. month April\u0004Apr": "Kwi",
    "abbrev. month August\u0004Aug": "Sie",
    "abbrev. month December\u0004Dec": "Gru",
    "abbrev. month February\u0004Feb": "Lut",
    "abbrev. month January\u0004Jan": "Sty",
    "abbrev. month July\u0004Jul": "Lip",
    "abbrev. month June\u0004Jun": "Cze",
    "abbrev. month March\u0004Mar": "Mar",
    "abbrev. month May\u0004May": "Maj",
    "abbrev. month November\u0004Nov": "Lis",
    "abbrev. month October\u0004Oct": "Pa\u017a",
    "abbrev. month September\u0004Sep": "Wrz",
    "cmn-clipboard": "Skopiowano do schowka",
    "cmn-close": "Zamknij",
    "cmn-currency-symbol": "z\u0142",
    "cmn-declination-donated": [
      "Wp\u0142aci\u0142a",
      "Wp\u0142aci\u0142y",
      "Wp\u0142aci\u0142o",
      "Wp\u0142aci\u0142o"
    ],
    "cmn-declination-person": [
      "osoba",
      "osoby",
      "os\u00f3b",
      "os\u00f3b"
    ],
    "cmn-error": "B\u0142\u0105d",
    "cmn-load-more": "Zobacz wi\u0119cej",
    "cmn-send": "Wy\u015blij",
    "cmn-support-booked": "Twoja wp\u0142ata zosta\u0142a poprawnie zaksi\u0119gowana. Dzi\u0119kujemy!",
    "ctg-find-project": "Znajd\u017a zbi\u00f3rk\u0119",
    "ctg-see-results-for": "Zobacz wyniki dla",
    "fb-share-popup-msg": "Udost\u0119pni\u0142e\u015b t\u0119 zbi\u00f3rk\u0119.",
    "fb-share-popup-title": "Gratulacje!",
    "form-err-sth-went-wrong": "Co\u015b posz\u0142o nie tak.",
    "invc-download": "Pobierz",
    "mng-notif-email-added": "Zaproszenia zosta\u0142y wys\u0142ane.",
    "notif-title-ready": "Gotowe!",
    "one letter Friday\u0004F": "P",
    "one letter Monday\u0004M": "P",
    "one letter Saturday\u0004S": "S",
    "one letter Sunday\u0004S": "N",
    "one letter Thursday\u0004T": "C",
    "one letter Tuesday\u0004T": "W",
    "one letter Wednesday\u0004W": "\u015a",
    "prowiz-unsaved-changes": "Masz niezapisane zmiany. Czy na pewno chcesz opu\u015bci\u0107 stron\u0119?",
    "radio-no": "Nie",
    "radio-yes": "Tak",
    "shrwiz-emails-contacts-full": "Doda\u0142e\u015b ju\u017c maksymaln\u0105 ilo\u015b\u0107 kontakt\u00f3w.",
    "shrwiz-emails-contacts-remaining %s": [
      "Mo\u017cesz doda\u0107 jeszcze %s kontakt.",
      "Mo\u017cesz doda\u0107 jeszcze %s kontakty.",
      "Mo\u017cesz doda\u0107 jeszcze %s kontakt\u00f3w.",
      "Mo\u017cesz doda\u0107 jeszcze %s kontakt\u00f3w."
    ],
    "shrwiz-emails-emails-full": "Doda\u0142e\u015b ju\u017c maksymaln\u0105 ilo\u015b\u0107 adres\u00f3w.",
    "shrwiz-emails-emails-remaining %s": [
      "Wpisz %s adres e-mail oddzielony przecinkiem.",
      "Wpisz %s adresy e-mail oddzielone przecinkami.",
      "Wpisz %s adres\u00f3w e-mail oddzielonych przecinkami.",
      "Wpisz %s adres\u00f3w e-mail oddzielonych przecinkami."
    ],
    "supwiz-channel-credit-card": "Karta",
    "supwiz-failed-or-canceled-payment": "P\u0142atno\u015b\u0107 nie powiod\u0142a si\u0119 lub zosta\u0142a przerwana.",
    "supwiz-other-amount": "Inna kwota",
    "supwiz-other-amount-short": "Inna",
    "supwiz-form-err-donation-amount": "Upewnij si\u0119, \u017ce ta warto\u015b\u0107 jest mniejsza lub r\u00f3wna 30000",
    "supwiz-form-err-donation-threshold %s %s %s": "Maksymalna wp\u0142ata na rozw\u00f3j serwisu Pomagam.pl to %s% (%s %s)",
  };
  for (const key in newcatalog) {
    django.catalog[key] = newcatalog[key];
  }
  

  if (!django.jsi18n_initialized) {
    django.gettext = function(msgid) {
      const value = django.catalog[msgid];
      if (typeof value === 'undefined') {
        return msgid;
      } else {
        return (typeof value === 'string') ? value : value[0];
      }
    };

    django.ngettext = function(singular, plural, count) {
      const value = django.catalog[singular];
      if (typeof value === 'undefined') {
        return (count == 1) ? singular : plural;
      } else {
        return value.constructor === Array ? value[django.pluralidx(count)] : value;
      }
    };

    django.gettext_noop = function(msgid) { return msgid; };

    django.pgettext = function(context, msgid) {
      let value = django.gettext(context + '\x04' + msgid);
      if (value.includes('\x04')) {
        value = msgid;
      }
      return value;
    };

    django.npgettext = function(context, singular, plural, count) {
      let value = django.ngettext(context + '\x04' + singular, context + '\x04' + plural, count);
      if (value.includes('\x04')) {
        value = django.ngettext(singular, plural, count);
      }
      return value;
    };

    django.interpolate = function(fmt, obj, named) {
      if (named) {
        return fmt.replace(/%\(\w+\)s/g, function(match){return String(obj[match.slice(2,-2)])});
      } else {
        return fmt.replace(/%s/g, function(match){return String(obj.shift())});
      }
    };


    /* formatting library */

    django.formats = {
    "DATETIME_FORMAT": "j E Y H:i",
    "DATETIME_INPUT_FORMATS": [
      "%d.%m.%Y %H:%M:%S",
      "%d.%m.%Y %H:%M:%S.%f",
      "%d.%m.%Y %H:%M",
      "%Y-%m-%d %H:%M:%S",
      "%Y-%m-%d %H:%M:%S.%f",
      "%Y-%m-%d %H:%M",
      "%Y-%m-%d"
    ],
    "DATE_FORMAT": "j E Y",
    "DATE_INPUT_FORMATS": [
      "%d.%m.%Y",
      "%d.%m.%y",
      "%y-%m-%d",
      "%Y-%m-%d"
    ],
    "DECIMAL_SEPARATOR": ",",
    "FIRST_DAY_OF_WEEK": 1,
    "MONTH_DAY_FORMAT": "j E",
    "NUMBER_GROUPING": 3,
    "SHORT_DATETIME_FORMAT": "d.m.Y H:i",
    "SHORT_DATE_FORMAT": "d.m.Y",
    "THOUSAND_SEPARATOR": "\u00a0",
    "TIME_FORMAT": "H:i",
    "TIME_INPUT_FORMATS": [
      "%H:%M:%S",
      "%H:%M:%S.%f",
      "%H:%M"
    ],
    "YEAR_MONTH_FORMAT": "F Y"
  };

    django.get_format = function(format_type) {
      const value = django.formats[format_type];
      if (typeof value === 'undefined') {
        return format_type;
      } else {
        return value;
      }
    };

    /* add to global namespace */
    globals.pluralidx = django.pluralidx;
    globals.gettext = django.gettext;
    globals.ngettext = django.ngettext;
    globals.gettext_noop = django.gettext_noop;
    globals.pgettext = django.pgettext;
    globals.npgettext = django.npgettext;
    globals.interpolate = django.interpolate;
    globals.get_format = django.get_format;

    django.jsi18n_initialized = true;
  }
};

